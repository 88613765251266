import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import camelize from 'camelize';
import decamelize from 'decamelize';
import _ from 'lodash';
import forEach from 'lodash/forEach';
import MUISx from 'mui-sx';
import { FieldPath, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import GroupsProjectFormAutocomplete from 'components/common/GroupsProjectFormAutocomplete';
import Icon from 'components/common/Icon';
import Loader from 'components/common/Loader';
import TechnologiesAutocomplete from 'components/common/TechnologiesAutocomplete';

import { ModalContext } from 'contexts/ModalContext';

import { useGetGroupsQuery } from 'domain/group/apiSlice';
import { IGroup } from 'domain/group/types';
import { useGetProjectQuery, useUpdateProjectMutation } from 'domain/project/apiSlice';
import { PeriodType, ProjectPrivacy } from 'domain/project/enums';
import { IUpdateProjectFormData } from 'domain/project/schemas/update';
import {
  formatAttributesToSubmit,
  initialValues,
  buildValidationSchema,
} from 'domain/project/schemas/updateWithoutUserRoles';
import { useGetTechnologiesQuery } from 'domain/technology/apiSlice';
import { ITechnology } from 'domain/technology/types';

import { useNotifications } from 'hooks/useNotifications';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { generateBackendErrorMessages } from 'utils/error';
import { buildErrorMessage } from 'utils/form';
import { getSortParameter } from 'utils/runsack';

import styles from './styles';
import { IUpdateProjectFormProps } from './types';

const UpdateProjectForm: FC<IUpdateProjectFormProps> = props => {
  const { projectId } = props;

  const [groupName, setGroupName] = useState<string>('');
  const [technologyName, setTechnologyName] = useState<string>('');

  const { t } = useTranslation(['common', 'reportHoursByProject']);
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const [debouncedGroupName] = useDebounce(groupName, DEFAULT_DEBOUNCE_DELAY_MS);
  const [debouncedTechnologyName] = useDebounce(technologyName, DEFAULT_DEBOUNCE_DELAY_MS);

  const {
    data: technologiesData,
    isLoading: isTechnologiesLoading,
    isFetching: isTechnologiesFetching,
  } = useGetTechnologiesQuery({
    nameCont: debouncedTechnologyName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const {
    data: groupsData,
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching,
  } = useGetGroupsQuery({
    nameCont: debouncedGroupName,
    sort: getSortParameter('descendants_names', 'asc'),
  });

  const {
    data: projectData,
    isLoading: isProjectDataLoading,
    isFetching: isProjectDataFetching,
    isError: isProjectDataError,
    isSuccess: isProjectDataSuccess,
  } = useGetProjectQuery(projectId);

  useEffect(() => {
    if (isProjectDataSuccess) {
      const values = initialValues(projectData);

      forEach(values, (value, key) => {
        setValue(key as FieldPath<IUpdateProjectFormData>, value, { shouldDirty: true });
      });
    }
  }, [isProjectDataSuccess]);

  const { closeModal } = useContext(ModalContext);

  const [updateProject, { isLoading: isCreateProjectLoading }] = useUpdateProjectMutation();

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateProjectFormData>({
    defaultValues: initialValues(projectData),
    resolver: yupResolver(buildValidationSchema()),
  });

  const {
    field: { value: groups, onChange: onGroupsChange },
  } = useController({ name: 'groups', control });

  const {
    field: { value: periodType, onChange: onPeriodTypeChange },
  } = useController({ name: 'periodType', control });

  const {
    field: { value: technologies, onChange: onTechnologiesChange },
  } = useController({ name: 'technologies', control });

  const {
    field: { value: billable, onChange: onBillableChange },
  } = useController({ name: 'billable', control });

  const {
    field: { value: taskRequired, onChange: onTaskRequiredChange },
  } = useController({ name: 'taskRequired', control });

  const {
    field: { value: description, onChange: onDescriptionChange },
  } = useController({ name: 'description', control });

  const {
    field: { value: privacy, onChange: onPrivacyChange },
  } = useController({ name: 'privacy', control });

  const {
    field: { value: uniqueTrackedTimeDescription, onChange: onUniqueTrackedTimeDescriptionChange },
  } = useController({ name: 'uniqueTrackedTimeDescription', control });

  const periodTypeValues = useMemo(() => {
    return {
      [PeriodType.ongoing]: t('reportHoursByProject:updateProjectForm.fields.periodType.values.onGoing'),
      [PeriodType.timebound]: t('reportHoursByProject:updateProjectForm.fields.periodType.values.timebound'),
    };
  }, []);

  const privacyValues = useMemo(() => {
    return {
      [ProjectPrivacy.public]: t('reportHoursByProject:updateProjectForm.fields.privacy.values.public'),
      [ProjectPrivacy.private]: t('reportHoursByProject:updateProjectForm.fields.privacy.values.private'),
    };
  }, []);

  const onSubmit = async (formData: IUpdateProjectFormData) => {
    try {
      const decamelizedFormData = {
        ...formData,
        periodType: formData.periodType && (decamelize(formData.periodType) as PeriodType),
      };
      await updateProject(formatAttributesToSubmit(decamelizedFormData, projectData?.project?.id as number)).unwrap();
      showSuccessNotification();
    } catch (error) {
      if (error.data) {
        const errors = generateBackendErrorMessages(error);
        for (const message of errors) {
          showErrorNotification(message);
        }
      }
    } finally {
      closeModal();
    }
  };

  const handleCurrentGroupsChange = (groups: Array<IGroup>) => {
    if (_.isEmpty(groups)) {
      onGroupsChange([]);
      return;
    }
    onGroupsChange(groups);
  };

  const handleCurrentTechnologiesChange = (technologies: Array<ITechnology>) => {
    if (_.isEmpty(technologies)) {
      onTechnologiesChange([]);
      return;
    }
    onTechnologiesChange(technologies);
  };

  const isDataLoading =
    isGroupsLoading || isTechnologiesLoading || isCreateProjectLoading || isProjectDataLoading || isProjectDataFetching;
  const isDataError = isProjectDataError;

  if (isDataError) {
    return <Alert severity="error">{t('common:defaultErrorNotificationText')}</Alert>;
  }

  if (isDataLoading) {
    return <Loader />;
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Div sx={styles.root}>
        <Div sx={styles.topRow}>
          <TextField
            sx={styles.nameField}
            fullWidth
            type="text"
            {...register('name')}
            label={t('reportHoursByProject:updateProjectForm.fields.name.label')}
            inputProps={{ maxLength: 30 }}
            InputLabelProps={{ sx: styles.placeholder }}
            error={Boolean(errors.name)}
            helperText={buildErrorMessage(t, errors.name)}
          />

          <TextField
            sx={styles.periodTypeField}
            fullWidth
            select
            value={camelize(periodType)}
            onChange={event => onPeriodTypeChange(decamelize(event.target.value) as PeriodType)}
            label={t('reportHoursByProject:updateProjectForm.fields.periodType.label')}
            InputLabelProps={{ sx: styles.selectPlaceholder }}
            error={Boolean(errors.periodType)}
          >
            <MenuItem value={camelize(PeriodType.ongoing)}>{periodTypeValues[PeriodType.ongoing]}</MenuItem>
            <MenuItem value={camelize(PeriodType.timebound)}>{periodTypeValues[PeriodType.timebound]}</MenuItem>
          </TextField>
        </Div>

        <TextField
          sx={styles.descriptionField}
          fullWidth
          type="text"
          value={description}
          onChange={onDescriptionChange}
          label={t('reportHoursByProject:updateProjectForm.fields.description.label')}
          inputProps={{ maxLength: 300 }}
          InputLabelProps={{ sx: styles.placeholder }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!_.isEmpty(description) && (
                  <IconButton onClick={() => setValue('description', '')} sx={styles.clearButton}>
                    <Icon sx={styles.clearIcon} name="closeWithoutCircle" />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          error={Boolean(errors.description)}
        />

        <Div sx={styles.topRow}>
          <Div sx={MUISx(styles.field, styles.groupsField)}>
            <GroupsProjectFormAutocomplete
              groups={groupsData?.groups ?? []}
              currentGroups={groups}
              onCurrentGroupsChange={handleCurrentGroupsChange}
              setGroupName={setGroupName}
              isError={Boolean(errors.groups)}
              isGroupsFetching={isGroupsFetching}
              label={t('reportHoursByProject:updateProjectForm.fields.groups.label')}
            />
          </Div>

          <TextField
            sx={styles.privacyField}
            fullWidth
            select
            value={privacy}
            onChange={onPrivacyChange}
            label={t('reportHoursByProject:updateProjectForm.fields.privacy.label')}
            InputLabelProps={{ sx: styles.selectPlaceholder }}
            error={Boolean(errors.privacy)}
          >
            <MenuItem value={ProjectPrivacy.private}>{privacyValues[ProjectPrivacy.private]}</MenuItem>
            <MenuItem value={ProjectPrivacy.public}>{privacyValues[ProjectPrivacy.public]}</MenuItem>
          </TextField>
        </Div>

        <Div sx={MUISx(styles.field, styles.technologiesField)}>
          <TechnologiesAutocomplete
            technologies={technologiesData?.technologies ?? []}
            currentTechnologies={technologies ?? []}
            onCurrentTechnologiesChange={handleCurrentTechnologiesChange}
            setTechnologyName={setTechnologyName}
            isError={Boolean(errors.technologies)}
            isTechnologiesFetching={isTechnologiesFetching}
            label={t('reportHoursByProject:updateProjectForm.fields.technologies.label')}
          />
        </Div>

        <Div sx={styles.switches}>
          <FormControlLabel
            control={<Switch checked={billable} onChange={onBillableChange} />}
            label={
              <Typography variant="subtitle1">
                {t('reportHoursByProject:updateProjectForm.fields.billable.label')}
              </Typography>
            }
          />

          <FormControlLabel
            control={<Switch checked={taskRequired} onChange={onTaskRequiredChange} />}
            label={
              <Typography variant="subtitle1">
                {t('reportHoursByProject:updateProjectForm.fields.taskRequired.label')}
              </Typography>
            }
          />

          <FormControlLabel
            control={<Switch checked={uniqueTrackedTimeDescription} onChange={onUniqueTrackedTimeDescriptionChange} />}
            label={
              <Typography variant="subtitle1">
                {t('reportHoursByProject:updateProjectForm.fields.uniqueTrackedTimeDescription.label')}
              </Typography>
            }
          />
        </Div>
      </Div>
      <Div sx={styles.buttons}>
        <Button sx={styles.createProjectButton} type="submit" disabled={isDataLoading}>
          {t('reportHoursByProject:updateProjectForm.buttons.saveChanges')}
        </Button>
        <Button type="reset" variant="outlined" onClick={closeModal} disabled={isDataLoading}>
          {t('reportHoursByProject:updateProjectForm.buttons.cancel')}
        </Button>
      </Div>
    </form>
  );
};

export default UpdateProjectForm;
