export enum ProjectState {
  active = 'active',
  archived = 'archived',
}

export enum PeriodType {
  ongoing = 'ongoing',
  timebound = 'timebound',
}

export enum ProjectRole {
  projectManager = 'projectManager',
  productDirector = 'productDirector',
  teamLead = 'teamLead',
}

export enum ProjectAccessLevel {
  admin = 'admin',
  manager = 'manager',
  user = 'user',
}

export enum ProjectPrivacy {
  public = 'public',
  private = 'private',
}
