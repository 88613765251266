import _ from 'lodash';

import { getGeneralColumnAccessor, getHoursColumnAccessor } from 'utils/string';

import { ICountedColumnsOptions } from './types';

export const getCountedColumns = (options: ICountedColumnsOptions) => {
  const {
    columnTitles,
    columns,
    isProjectInfoRow,
    initialcolumnsAccumulator,
    renderHoursCell,
    resourceId,
    resourceUsageId,
  } = options;

  return columnTitles.reduce((columnsAccumulator, columnTitle, index) => {
    const columnIndex = index + 1;
    const generalColumnAccessor = getGeneralColumnAccessor(columnIndex);

    const currentColumn = columns.find(column => {
      const mainColumnAccessor = _.head(Object.keys(column));
      return mainColumnAccessor === generalColumnAccessor;
    });

    const planColumnAccessor = getHoursColumnAccessor('plan', columnIndex);
    const actualColumnAccessor = getHoursColumnAccessor('actual', columnIndex);
    const varianceColumnAccessor = getHoursColumnAccessor('variance', columnIndex);

    const planColumnValue = currentColumn[generalColumnAccessor].plan;
    const actualColumnValue = currentColumn[generalColumnAccessor].actual;
    const varianceColumnValue = currentColumn[generalColumnAccessor].variance;

    const isPlanCouldBeUpdated = columnTitle[generalColumnAccessor].planCouldBeUpdated;
    const startDate = columnTitle[generalColumnAccessor].dateRange.dateStart;
    const endDate = columnTitle[generalColumnAccessor].dateRange.dateEnd;

    return {
      ...columnsAccumulator,
      [planColumnAccessor]: renderHoursCell({
        hours: planColumnValue,
        isProjectInfoRow,
        isPlanCouldBeUpdated,
        resourceId,
        resourceUsageId,
        startDate,
        endDate,
      }),
      [actualColumnAccessor]: renderHoursCell({ hours: actualColumnValue, isProjectInfoRow }),
      [varianceColumnAccessor]: renderHoursCell({
        hours: varianceColumnValue,
        isProjectInfoRow,
        isVarianceCell: true,
      }),
    };
  }, initialcolumnsAccumulator);
};
