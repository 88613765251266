import { FC } from 'react';

import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';

import { IResourceTrackedTimeEntry } from 'domain/resource/trackedTimeEntry/types';

import { formatDurationFromNumberToObject } from 'utils/durationFormat';

import RenderInput from './RenderInput';
import styles from './styles';
import { IInputWithOptionsProps } from './types';

const TOTAL_ENTRIES_TO_SHOW = 10;

const getOptionLabel = (option: IResourceTrackedTimeEntry | string) => {
  if (typeof option === 'string') {
    return option;
  }
  if (option.description) {
    return `${option.id}${option.description}`;
  }
  return '';
};

const InputWithOptions: FC<IInputWithOptionsProps> = props => {
  const {
    description,
    onDescriptionChange,
    isCreateResourceTrackedEntityLoading,
    trackedTimeEntries,
    setDuration,
    setCurrentTimeTrackerTags,
    setCurrentTimeTrackerTrackable,
    handleCurrentTimeTrackerTrackableChange,
  } = props;

  const handleOptionOnChange = (event, localProps) => {
    event.stopPropagation();
    onDescriptionChange(localProps.description);
    setDuration(formatDurationFromNumberToObject(localProps.duration));
    setCurrentTimeTrackerTags(localProps.tags);
    const currentTimeTrackerTrackable = {
      task: localProps.task,
      trackable: {
        billable: localProps.billable,
        id: localProps.trackableId,
        name: localProps.trackableName,
        state: localProps.task?.state,
        type: localProps.trackableType,
      },
    };
    setCurrentTimeTrackerTrackable(currentTimeTrackerTrackable);
    handleCurrentTimeTrackerTrackableChange(currentTimeTrackerTrackable);
  };

  const listItemStyles = {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
  };

  const renderOption = (props, option) => (
    <li key={option.id} style={listItemStyles as React.CSSProperties} {...props}>
      {option.description}
    </li>
  );

  return (
    <Autocomplete
      onChange={handleOptionOnChange}
      autoHighlight={true}
      value={description}
      freeSolo
      disableClearable
      clearOnEscape
      disabled={isCreateResourceTrackedEntityLoading}
      options={[...trackedTimeEntries].slice(0, TOTAL_ENTRIES_TO_SHOW)}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      renderInput={(parameters: AutocompleteRenderInputParams) => (
        <RenderInput parameters={parameters} onDescriptionChange={onDescriptionChange} />
      )}
      sx={styles}
    />
  );
};

export default InputWithOptions;
