import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    padding: '12px 16px',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'border.main',
    '&:last-of-type': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: 'background.opacityGray',
      transition: 'background-color 0.2s linear',
    },
    '&:hover div[data-actions=entity-action]': {
      visibility: 'visible',
      opacity: 1,
      transition: 'visibility 0.2s linear, opacity 0.2s linear',
    },
    height: '48px',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
  },
  descriptionTooltip: {
    maxWidth: '800px',
    top: '35px',
  },
  descriptionBlock: {
    position: 'relative',
    width: 'fit-content',
    maxWidth: 'calc(100% - 430px)',
    height: '100%',
    alignItems: 'center',
    marginLeft: '12px',
    '& input': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      minWidth: '50px',
      minHeight: '1.5em',
      padding: 0,
      margin: 0,
      marginTop: '10px',
      fontSize: 16,
      fontWeight: 400,
      fontFamily: 'inherit',
      border: 'none',
      color: 'text.primary',
      background: 'none',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& input:focus': {
      outline: 'none',
    },
  },
  inputMirror: {
    margin: '16px 0px',
    visibility: 'hidden',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    width: 'fit-content',
    minWidth: '50px',
    minHeight: '1.5em',
  },
  duration: {
    marginRight: '19px',
  },
  projectTagsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  projectOptionBlock: {
    margin: '0 8px',
    overflow: 'hidden',
    minWidth: '85px',
  },
  tagsOptionBlock: {
    margin: '0 8px',
    overflow: 'hidden',
    minWidth: '85px',
  },
  billableOptionBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  billableProjectSign: {
    color: 'text.green',
  },
  dateWithDuration: {
    width: '50px',
    padding: 0,
    color: 'text.blackLight',
  },
  actions: {
    display: 'flex',
    visibility: 'hidden',
    opacity: 0,
    flexFlow: 'row nowrap',
    height: 48,
    alignItems: 'center',
    paddingLeft: '24px',
  },
  actionButtonIcon: {
    width: '24px',
    height: '24px',
  },
  actionButton: {
    width: '24px',
    height: '24px',
  },
  copyButton: {
    marginRight: '10px',
  },
  mobileEntryList: {
    height: '224px',
  },
  mobileEntryContent: {
    minWidth: '100%',
  },
  descriptionBlockDuplicated: {
    '& input': {
      color: 'text.red',
    },
  },
  hideActions: {
    visibility: 'visible',
    opacity: 1,
  },
};

export default styles;
