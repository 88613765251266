import { FC, useMemo, useContext } from 'react';

import { Button, Typography } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import CreateResourceAllocation from 'components/common/CreateResourceAllocation';
import Div from 'components/common/Div';
import ResourceUsageActions from 'components/common/ResourceUsageActions';
import ResourceUsageWorkloadWithDatesButton from 'components/common/ResourceUsageWorkloadWithDatesButton';
import CommonTable from 'components/common/Table';
import TableHeader from 'components/common/TableHeader';
import VacancyActions from 'components/common/VacancyActions';
import VacancyFteWithDatesButton from 'components/common/VacancyFteWithDatesButton';

import { ModalContext } from 'contexts/ModalContext';

import {
  IReportHoursByProject,
  IReportHoursByProjectResourceUsageWithColumns,
  IReportHoursByProjectVacancyWithColumns,
} from 'domain/report/hoursByProject/types';
import { IResource } from 'domain/resource/types';
import { getTechnologies as getResourceUsageTechnologies } from 'domain/resource/usage/presenter';
import { getTechnologies as getVacancyTechnologies } from 'domain/vacancy/presenter';

import { formatToUSNumber } from 'utils/number';
import { getFullName } from 'utils/person';
import { getGeneralColumnAccessor, getHoursColumnAccessor } from 'utils/string';

import UpdateProjectPlannedHoursForm from './components/UpdateProjectPlannedHoursForm';
import { getCountedColumns } from './service';
import styles from './styles';
import { IPreparedTableColumnsOptions, IRenderHoursCellOptions, IDetailedProjectTableProps } from './types';

const DetailedProjectTable: FC<IDetailedProjectTableProps> = props => {
  const { data, isError, isLoading, groupByPeriod } = props;
  const { openModal } = useContext(ModalContext);

  const { t } = useTranslation('reportHoursByProject');

  const renderResourceUsageCell = (
    detailedProject: IReportHoursByProject,
    resourceUsage: IReportHoursByProjectResourceUsageWithColumns,
  ) => {
    const fullName = getFullName(resourceUsage);
    const technologies = getResourceUsageTechnologies(resourceUsage);

    const handleAddResourceUsageClick = () => {
      const resourceData: IResource = {
        id: resourceUsage.resourceId,
        firstName: resourceUsage.firstName,
        lastName: resourceUsage.lastName,
        state: resourceUsage.state,
        technologies: resourceUsage.technologies,
      };

      openModal({
        title: t('addResourceUsageModalTitle', { projectName: detailedProject.name }),
        content: <CreateResourceAllocation currentProject={detailedProject} currentResource={resourceData} />,
      });
    };

    const canEditAllocation = resourceUsage.permissions.canEditAllocation;

    return (
      <Div sx={styles.resourcesAndVacanciesHeadCellRoot}>
        <ResourceUsageActions
          resourceUsage={resourceUsage}
          project={detailedProject}
          canEditAllocation={canEditAllocation}
        />
        <Div sx={styles.resourcesAndVacanciesHeadCell}>
          <Typography variant="heading3">{fullName}</Typography>
          <Typography variant="subtitle2">{technologies}</Typography>
        </Div>
        {_.isNil(resourceUsage.workload) ? (
          <Button
            sx={styles.addResourceUsageButton}
            variant="outlined"
            onClick={handleAddResourceUsageClick}
            disabled={!canEditAllocation}
          >
            {t('createResourceUsageAndVacancyButton')}
          </Button>
        ) : (
          <Div sx={styles.fteButton}>
            <ResourceUsageWorkloadWithDatesButton project={detailedProject} resourceUsage={resourceUsage} />
          </Div>
        )}
      </Div>
    );
  };

  const renderVacancyCell = (
    detailedProject: IReportHoursByProject,
    vacancy: IReportHoursByProjectVacancyWithColumns,
  ) => {
    const technologies = getVacancyTechnologies(vacancy);

    return (
      <Div sx={styles.resourcesAndVacanciesHeadCellRoot}>
        <VacancyActions vacancy={vacancy} project={detailedProject} groupId={detailedProject.groupId} />
        <Div sx={styles.resourcesAndVacanciesHeadCell}>
          <Div sx={styles.vacancy}>
            <Typography variant="heading3">{technologies}</Typography>
            <Typography sx={styles.vacancyIcon}>🪑</Typography>
          </Div>
          <Typography variant="subtitle2">{t('vacancy')}</Typography>
        </Div>
        {!_.isNil(vacancy.fte) && (
          <Div sx={styles.fteButton}>
            <VacancyFteWithDatesButton project={detailedProject} groupId={detailedProject.groupId} vacancy={vacancy} />
          </Div>
        )}
      </Div>
    );
  };

  const renderHoursCell = (options: IRenderHoursCellOptions) => {
    const {
      hours,
      isProjectInfoRow,
      isVarianceCell = false,
      isPlanCouldBeUpdated = false,
      resourceId,
      resourceUsageId,
      startDate,
      endDate,
    } = options;

    const hoursToUSFormatted = formatToUSNumber(hours);

    const isAllocationZeroStyle = isVarianceCell && !isProjectInfoRow && hours === 0;
    const isAllocationNegativeStyle = isVarianceCell && hours < 0;
    const isAllocated = _.isNil(resourceId) ? false : !_.isNil(resourceUsageId);

    const handlePlannedHoursClick = () => {
      openModal({
        title: t('updatePlannedHoursForm.title'),
        content: (
          <UpdateProjectPlannedHoursForm
            hours={hours}
            resourceId={resourceId}
            resourceUsageId={resourceUsageId}
            startDate={startDate}
            endDate={endDate}
          />
        ),
      });
    };

    if (isPlanCouldBeUpdated && !isProjectInfoRow && isAllocated) {
      return (
        <Button variant="outlined" sx={styles.transparentButton} onClick={handlePlannedHoursClick}>
          <Typography sx={styles.planUpdatedCell} component="p" variant="subtitle1">
            {hoursToUSFormatted}
          </Typography>
        </Button>
      );
    }

    return (
      <Typography
        sx={MUISx(
          { condition: isAllocationZeroStyle, sx: styles.allocationZero },
          { condition: isAllocationNegativeStyle, sx: styles.allocationNegative },
        )}
        variant={isProjectInfoRow ? 'h4' : 'subtitle1'}
      >
        {hoursToUSFormatted}
      </Typography>
    );
  };

  const getPreparedTableColumns = (options: IPreparedTableColumnsOptions) => {
    const { columnTitles, columns, isProjectInfoRow = false, resourceId, resourceUsageId } = options;

    const resourceTotalColumn = {
      resourcesTotal: (
        <Typography sx={styles.projectInfoRowTitle} variant="h4">
          {t('table.headers.mainColumnHeader')}
        </Typography>
      ),
    };

    const initialcolumnsAccumulator = isProjectInfoRow ? resourceTotalColumn : {};

    return getCountedColumns({
      columnTitles,
      columns,
      isProjectInfoRow,
      initialcolumnsAccumulator,
      renderHoursCell,
      resourceId,
      resourceUsageId,
    });
  };

  const PROJECTS_LIST_COLUMNS = useMemo(() => {
    const mainHeader = {
      Header: '',
      accessor: 'resourcesTotal',
      minWidth: '420px',
    };

    const { columnTitles } = data;

    const otherHeaders = columnTitles.map((columnTitle, index) => {
      const columnIndex = index + 1;
      const generalColumnAccessor = getGeneralColumnAccessor(columnIndex);
      const currentColumn = columnTitle[generalColumnAccessor];

      return {
        Header: <TableHeader column={currentColumn} groupByPeriod={groupByPeriod} />,
        accessor: getGeneralColumnAccessor(columnIndex),
        columns: [
          {
            Header: (
              <Typography sx={styles.columnHeader} variant="tableHeading">
                {t('table.headers.plan')}
              </Typography>
            ),
            accessor: getHoursColumnAccessor('plan', columnIndex),
            minWidth: '85px',
          },
          {
            Header: (
              <Typography sx={styles.columnHeader} variant="tableHeading">
                {t('table.headers.actual')}
              </Typography>
            ),
            accessor: getHoursColumnAccessor('actual', columnIndex),
            minWidth: '85px',
          },
          {
            Header: (
              <Typography sx={styles.columnHeader} variant="tableHeading">
                {t('table.headers.variance')}
              </Typography>
            ),
            accessor: getHoursColumnAccessor('variance', columnIndex),
            minWidth: '85px',
          },
        ],
      };
    });
    return [mainHeader, ...otherHeaders];
  }, [data]);

  const tableData = useMemo(() => {
    const {
      project: detailedProject,
      project: { resourceUsages, vacancies, columns: projectColumns },
    } = data.report;
    const { columnTitles } = data;

    const preparedTableColumnsOptions = {
      columnTitles,
      columns: projectColumns,
      isProjectInfoRow: true,
    };
    const projectHoursInfoRow = getPreparedTableColumns(preparedTableColumnsOptions);

    const resourceUsagesRows = resourceUsages.map(resourceUsage => {
      const preparedTableColumnsOptions = {
        columnTitles,
        columns: resourceUsage.columns,
        resourceId: resourceUsage.resourceId,
        resourceUsageId: resourceUsage.id,
      };
      const columns = getPreparedTableColumns(preparedTableColumnsOptions);

      return {
        resourcesTotal: renderResourceUsageCell(detailedProject, resourceUsage),
        ...columns,
      };
    });

    const vacanciesRows = vacancies.map(vacancy => {
      const preparedTableColumnsOptions = {
        columnTitles,
        columns: vacancy.columns,
      };
      const columns = getPreparedTableColumns(preparedTableColumnsOptions);

      return {
        resourcesTotal: renderVacancyCell(detailedProject, vacancy),
        ...columns,
      };
    });

    return [projectHoursInfoRow, ...resourceUsagesRows, ...vacanciesRows];
  }, [data]);

  return <CommonTable data={tableData} columns={PROJECTS_LIST_COLUMNS} isError={isError} isLoading={isLoading} />;
};

export default DetailedProjectTable;
