export default {
  title: 'Hours by Resources',
  exportButton: 'Export',
  hoursType: {
    all: 'all',
    billable: 'billable',
    nonBillable: 'non-billable',
  },
  filter: {
    resources_one: '{{ count }} Resource',
    resources_other: '{{ count }} Resources',
    detailedInfoLabel: 'Details Info',
    hoursType: 'Hours Type',
    ptoFilter: {
      title: 'PTO Filter',
      all: 'all',
      onlyPto: 'only PTO',
      withoutPto: 'without PTO',
    },
  },
  table: {
    columnsHeaders: {
      available: 'Available',
      expected: 'Expected',
      planned: 'Planned',
      actual: 'Actual',
      utilization: 'Utilization',
      resourcesTotal: 'Resources/Total',
      billable: 'Billable',
      nonBillable: 'Non-billable',
      specialization: 'Specialization',
      physicalLocation: 'Physical Location',
      hierarchy: 'Hierarchy',
      calendar: 'Calendar',
      startDate: 'Start Date',
      billableStartDate: 'Billable Start Date',
    },
    columnsTooltips: {
      available: 'Total available hours including billable, non-billable, and PTO time',
      expected: "Expected number of hours from the individual's profile",
      planned: 'Planned number of hours allocated to projects',
      actual: 'Actual number of hours enter by individual',
      utilization: 'Actual / Available',
    },
    actualHours: 'Actual Hours: {{ hours }}',
    availableHours: 'Available Hours: {{ hours }}',
    deny_access: 'You do not have the necessary permissions to access the project {{ header }}',
    addAllocationModalTitle: 'Add Allocation',
    createResourceUsageAndVacancyButton: 'Allocate',
  },
  tooltips: {
    addResourceAllocation: 'Allocate',
  },
  exportTitle: 'Hours by Resources report',
};
