import decamelize from 'decamelize';
import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IGroup } from 'domain/group/types';
import { PeriodType, ProjectPrivacy } from 'domain/project/enums';
import { ITechnology } from 'domain/technology/types';

import { maxTwoDigitsAfterDecimalRegExp } from 'utils/regexp';

export interface ICreateProjectFormData {
  name: string;
  description?: string;
  groups: Array<IGroup>;
  periodType: PeriodType;
  monthlyBudget: number;
  technologies: Array<ITechnology>;
  billable: boolean;
  taskRequired: boolean;
  privacy: ProjectPrivacy;
  uniqueTrackedTimeDescription: boolean;
}

export interface ICreateProjectFormDataToSubmit extends Omit<ICreateProjectFormData, 'groups' | 'technologies'> {
  groupIds: Array<number>;
  technologyIds: Array<number>;
}

const requiredValidation = 'forms:validation:required';

export const buildValidationFields = () => ({
  name: yup
    .string()
    .max(30, i18n.t('forms:validation:maxSymbols', { count: 30 }))
    .required(i18n.t(requiredValidation))
    .default(''),
  description: yup
    .string()
    .max(300, i18n.t('forms:validation:maxSymbols', { count: 300 }))
    .default(''),
  groups: yup.array().of(yup.mixed<IGroup>()).required(i18n.t(requiredValidation)).default([]),
  periodType: yup.mixed<PeriodType>().required(i18n.t(requiredValidation)).default(PeriodType.ongoing),
  monthlyBudget: yup.string().when('periodType', (periodType, schema) => {
    if (periodType === PeriodType.ongoing)
      return yup
        .number()
        .moreThan(-1, i18n.t('forms:validation:positiveNumber'))
        .typeError(i18n.t('forms:validation:mustBeNumber'))
        .test(
          i18n.t('forms:validation:maxDigitsAfterDecimalTest'),
          i18n.t('forms:validation:maxTwoDigitsAfterDecimal'),
          number => maxTwoDigitsAfterDecimalRegExp.test(String(number)),
        )
        .nullable()
        .default(null);
    return schema;
  }),
  technologies: yup.array().of(yup.mixed<ITechnology>()).required(i18n.t(requiredValidation)).default([]),
  billable: yup.boolean().default(true),
  taskRequired: yup.boolean().default(false),
  privacy: yup.mixed<ProjectPrivacy>().required(i18n.t(requiredValidation)).default(ProjectPrivacy.private),
  uniqueTrackedTimeDescription: yup.boolean().default(false),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = () => buildValidationSchema().getDefault();

export const formatAttributesToSubmit = (values: ICreateProjectFormData): ICreateProjectFormDataToSubmit => {
  const {
    name,
    description,
    groups,
    technologies,
    periodType,
    monthlyBudget,
    billable,
    taskRequired,
    privacy,
    uniqueTrackedTimeDescription,
  } = values;

  const technologyIds = technologies.map(technology => technology.id);
  const groupIds = groups.map(group => group.id);
  const decamelizedperiodType = decamelize(periodType);

  return {
    name,
    description,
    periodType: decamelizedperiodType as PeriodType,
    monthlyBudget,
    billable,
    taskRequired,
    technologyIds,
    groupIds,
    privacy,
    uniqueTrackedTimeDescription,
  };
};
