import { FC, useState } from 'react';

import { Button, Popover, Typography } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import { useScreen } from 'hooks';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import {
  loadingGetTimeTrackerTrackablesParameters,
  useGetTimeTrackerTrackablesQuery,
} from 'domain/timeTracker/trackable/apiSlice';

import { ITimeTrackerTrackableState } from 'types/timeTrackerTrackable';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { getSortParameter } from 'utils/runsack';

import TrackedTimeEntryProjectChip from '../TrackedTimeEntry/components/TrackedTimeEntryProjectChip';

import TimeTrackerTrackablesAutocomplete from './components/TimeTrackerTrackablesAutocomplete';
import styles from './styles';
import { ICreateProjectAndTaskButtonProps } from './types';

const CreateProjectAndTaskButton: FC<ICreateProjectAndTaskButtonProps> = props => {
  const {
    currentTimeTrackerTrackable,
    setCurrentTimeTrackerTrackable,
    isDisabled,
    onCurrentTimeTrackerTrackableChange = () => {},
  } = props;

  const { t } = useTranslation(['common', 'timeTracker']);

  const [timeTrackerTrackablesName, setTimeTrackerTrackablesName] = useState<string>('');

  const [debouncedTrackablesName] = useDebounce(timeTrackerTrackablesName, DEFAULT_DEBOUNCE_DELAY_MS);

  const timeTrackerTrackablesParameters = loadingGetTimeTrackerTrackablesParameters({
    nameCont: debouncedTrackablesName,
    paginated: false,
    s: getSortParameter('name', 'asc'),
  });
  const {
    data: timeTrackerTrackablesData,
    isFetching: isTimeTrackerTrackablesDataFetching,
    isError: isTimeTrackerTrackablesDataLoadingError,
  } = useGetTimeTrackerTrackablesQuery(timeTrackerTrackablesParameters);

  const [createProjectAndTaskElement, setCreateProjectAndTaskElement] = useState<HTMLButtonElement | null>(null);

  const handleCreateProjectAndTaskClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCreateProjectAndTaskElement(event.currentTarget);
  };

  const handleCreateProjectAndTaskClose = () => {
    setCreateProjectAndTaskElement(null);
    setTimeTrackerTrackablesName('');
  };

  const createProjectAndTaskOpen = Boolean(createProjectAndTaskElement);
  const createProjectAndTaskButtonId = open ? 'create-project-and-task' : undefined;

  const handleCurrentTimeTrackerTrackableChange = (timeTrackerTrackable: ITimeTrackerTrackableState) => {
    if (_.isNil(timeTrackerTrackable)) {
      setCurrentTimeTrackerTrackable(null);
      onCurrentTimeTrackerTrackableChange(null);
      return;
    }
    setCurrentTimeTrackerTrackable(timeTrackerTrackable);
    onCurrentTimeTrackerTrackableChange(timeTrackerTrackable);
  };

  const hasTimeTrackerTrackable = !_.isNil(currentTimeTrackerTrackable?.trackable);

  const { lessThanDesktop } = useScreen();
  return (
    <Div sx={styles.root}>
      {hasTimeTrackerTrackable ? (
        <Button
          variant="text"
          aria-describedby={createProjectAndTaskButtonId}
          onClick={handleCreateProjectAndTaskClick}
          type="button"
          sx={MUISx(styles.addProjectAndTaskButton, styles.trackableButton)}
          disabled={isDisabled}
        >
          <TrackedTimeEntryProjectChip
            isBillable={currentTimeTrackerTrackable.trackable?.billable}
            taskName={currentTimeTrackerTrackable.task?.name}
            projectName={currentTimeTrackerTrackable.trackable.name}
          />
        </Button>
      ) : (
        <Button
          variant="outlined"
          startIcon={<Icon sx={styles.plusIcon} name="plusBorderless" />}
          aria-describedby={createProjectAndTaskButtonId}
          onClick={handleCreateProjectAndTaskClick}
          type="button"
          sx={styles.addProjectAndTaskButton}
          disabled={isDisabled}
        >
          <Typography variant="h5">{t('timeTracker:createProjectAndTaskButton.button')}</Typography>
        </Button>
      )}

      <Popover
        id={createProjectAndTaskButtonId}
        open={createProjectAndTaskOpen}
        anchorEl={createProjectAndTaskElement}
        onClose={handleCreateProjectAndTaskClose}
        sx={MUISx(styles.popover, { condition: lessThanDesktop, sx: styles.removeLeft })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <TimeTrackerTrackablesAutocomplete
          options={timeTrackerTrackablesData?.trackables}
          isFetching={isTimeTrackerTrackablesDataFetching}
          isError={isTimeTrackerTrackablesDataLoadingError}
          setName={setTimeTrackerTrackablesName}
          name={timeTrackerTrackablesName}
          onCurrentValueChange={handleCurrentTimeTrackerTrackableChange}
          currentValue={currentTimeTrackerTrackable}
          onClose={handleCreateProjectAndTaskClose}
          placeholder={t('timeTracker:createProjectAndTaskButton.autocomplete.placeholder')}
        />
      </Popover>
    </Div>
  );
};

export default CreateProjectAndTaskButton;
