import * as yup from 'yup';

import i18n from 'locales/i18n';

import { IGroup } from 'domain/group/types';

import { convertDateToUTCString } from 'utils/dateTime';

import { IUserProfileMigration } from '../types';

const requiredValidation = 'forms:validation:required';

const dateFormatValidation = 'forms:validation:dateFormat';

export interface IUpdateLocationFormData {
  group: IGroup;
  receiptDate: string;
  eliminationDate?: string;
}

export interface IUpdateLocationPreparedFormData extends Omit<IUpdateLocationFormData, 'group'> {
  groupId: number;
  userId: number;
}

export interface IUpdateLocationFormDataToSubmit {
  userMigration: IUpdateLocationPreparedFormData;
  migrationId: number;
}

export const buildValidationFields = () => ({
  group: yup.mixed<IGroup>().required(i18n.t(requiredValidation)).nullable().default(null),
  receiptDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .required(i18n.t(requiredValidation))
    .nullable()
    .default(new Date()),
  eliminationDate: yup
    .date()
    .typeError(i18n.t(dateFormatValidation))
    .min(yup.ref('receiptDate'), i18n.t('forms:validation:startDateBeforeEndDate'))
    .nullable()
    .default(null),
});

export const buildValidationSchema = () => yup.object(buildValidationFields());

export const initialValues = (migration: IUserProfileMigration) => {
  const { receiptDate, eliminationDate, groupId } = migration;
  return {
    groupId,
    receiptDate,
    eliminationDate,
  };
};

export const formatAttributesToSubmit = (
  formData: IUpdateLocationFormDataToSubmit,
): IUpdateLocationFormDataToSubmit => {
  const { userMigration, migrationId } = formData;
  const { receiptDate, eliminationDate } = userMigration;

  const normalizedReceiptDate = convertDateToUTCString(new Date(receiptDate));
  const normalizedEliminationDate = eliminationDate && convertDateToUTCString(new Date(eliminationDate));

  const newUserMigration = {
    ...userMigration,
    receiptDate: normalizedReceiptDate,
    eliminationDate: normalizedEliminationDate,
  };

  return {
    userMigration: newUserMigration,
    migrationId: migrationId,
  };
};
