import { FC, useEffect } from 'react';

import { Close, SearchOutlined } from '@mui/icons-material';
import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';

import styles from './styles';
import { ITagsAutocompleteInputProps } from './types';

const TagsAutocompleteInput: FC<ITagsAutocompleteInputProps> = props => {
  const { isError, onTagsAutocompleteChange, isFetching, name, placeholder, setName } = props;

  const handleChange = event => {
    onTagsAutocompleteChange(event);
  };

  useEffect(() => {
    window.document.addEventListener('keydown', handleChange);
    return () => {
      window.document.removeEventListener('keydown', handleChange);
    };
  }, []);

  return (
    <TextField
      value={name}
      placeholder={placeholder}
      fullWidth
      autoFocus
      error={isError}
      InputLabelProps={{ sx: styles.selectPlaceholder }}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined sx={styles.inputIcon} />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {isFetching && (
              <InputAdornment position="end">
                <CircularProgress color="inherit" size={20} />
              </InputAdornment>
            )}
            {!isFetching && name && name.length > 0 && (
              <InputAdornment position="end">
                <IconButton onClick={() => setName('')}>
                  <Close sx={styles.inputIcon} />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
};

export default TagsAutocompleteInput;
