import { FC, useContext, useMemo } from 'react';

import { Typography, Alert } from '@mui/material';
import { parse } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';
import Loader from 'components/common/Loader';

import { ModalContext } from 'contexts/ModalContext';

import { useGetUserProfileMigrationsQuery } from 'domain/user/profile/migrations/apiSlice';
import { IUserProfileMigration } from 'domain/user/profile/migrations/types';

import { USER_MIGRATIONS_BETWEEN_N_MONTHS } from 'utils/constants';
import { formatToShortString, formatToDateWithFixedTimeZone } from 'utils/dateTime';

import CreateNewLocationButton from './components/CreateNewLocationButton';
import CreateNewLocationForm from './components/CreateNewLocationForm';
import MigrationsTable from './components/MigrationsTable';
import UpdateLocationForm from './components/UpdateLocationForm';
import styles from './styles';
import { IMigrationsProps } from './types';

const Migrations: FC<IMigrationsProps> = props => {
  const { user } = props;

  const { t } = useTranslation('userProfile');

  const today = new Date();

  const defaultEndDate = new Date(today.setMonth(today.getMonth() + USER_MIGRATIONS_BETWEEN_N_MONTHS));
  const defaultEndDateString = `${defaultEndDate.getFullYear()}-${
    defaultEndDate.getMonth() + 1
  }-${defaultEndDate.getDate()}`;

  const { data, isLoading, isError, error } = useGetUserProfileMigrationsQuery({
    startDate: user.employmentStartDate,
    endDate: user.employmentEndDate ?? defaultEndDateString,
    userIdsIn: [user.id],
  });

  const { openModal } = useContext(ModalContext);

  const buildMigrationsColums = useMemo(() => {
    return [
      {
        Header: <Typography variant="subtitle2">{t('migrations.group')}</Typography>,
        accessor: 'group',
      },
      {
        Header: <Typography variant="subtitle2">{t('migrations.dates')}</Typography>,
        accessor: 'dates',
      },
      {
        Header: '',
        accessor: 'actions',
      },
    ];
  }, []);

  const handleCreateNewLocationModalOpen = () => {
    openModal({
      title: t('migrations.addModalTitle'),
      content: <CreateNewLocationForm userId={user.id} />,
    });
  };

  const handleUpdateModalOpen = (migration: IUserProfileMigration) => {
    openModal({
      title: t('migrations.editModalTitle'),
      content: <UpdateLocationForm migration={migration} userId={user.id} />,
    });
  };

  const prepareTableData = (migration: IUserProfileMigration) => {
    const { hierarchy, receiptDate, eliminationDate } = migration;

    const formattedReceiptDate = formatToShortString(formatToDateWithFixedTimeZone(receiptDate));
    const formattedEliminationDate =
      eliminationDate && formatToShortString(formatToDateWithFixedTimeZone(eliminationDate));

    return {
      group: <Typography variant="subtitle1">{hierarchy}</Typography>,
      dates: (
        <Typography variant="subtitle1">{`${formattedReceiptDate} - ${formattedEliminationDate ?? '...'}`}</Typography>
      ),
      actions: (
        <Typography sx={styles.actions}>
          <Div sx={styles.actionEdit} onClick={() => handleUpdateModalOpen(migration)}>
            {t('migrations.actionEditLabel')}
          </Div>
        </Typography>
      ),
    };
  };

  const sortData = (data: IUserProfileMigration[]) => {
    return [...data].sort((a, b) => {
      const aReceiptDate = parse(a.receiptDate, 'yyyy-MM-dd', new Date());
      const bReceiptDate = parse(b.receiptDate, 'yyyy-MM-dd', new Date());
      if (aReceiptDate < bReceiptDate) {
        return 1;
      }
      if (aReceiptDate > bReceiptDate) {
        return -1;
      }
      return 0;
    });
  };

  const mappedData = useMemo(() => {
    const sortedData = sortData(data?.usersMigrations ?? []);
    return sortedData?.map(migration => prepareTableData(migration));
  }, [data]);

  if (isError) {
    return <Alert severity="error">{`${t('common:defaultErrorNotificationText')}: ${error}`}</Alert>;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Div sx={styles.container}>
      <Div sx={styles.header}>
        <Typography variant="heading2">{t('migrations.title')}</Typography>
        <CreateNewLocationButton
          buttonLabel={t('migrations.createNewLocationButton')}
          onAddNodeButtonClick={handleCreateNewLocationModalOpen}
        />
      </Div>
      {mappedData && <MigrationsTable data={mappedData} columns={buildMigrationsColums} />}
    </Div>
  );
};

export default Migrations;
