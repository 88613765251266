import React, { FC, useRef, useState, useEffect } from 'react';

import Div from 'components/common/Div';

import { ITimeTrackerTrackable } from 'domain/timeTracker/trackable/types';

import { KeyboardKeys } from 'enums/KeyboardKeys';

import { ITimeTrackerTrackableState } from 'types/timeTrackerTrackable';

import ProjectTasksInput from './components/ProjectTasksInput';
import ProjectTasksOptionsList from './components/ProjectTasksOptionsList';
import styles from './styles';
import { ITimeTrackerTrackablesAutocompleteProps } from './types';

const TimeTrackerTrackablesAutocomplete: FC<ITimeTrackerTrackablesAutocompleteProps> = props => {
  const { currentValue, onCurrentValueChange, options, isError, setName, name, isFetching, onClose, placeholder } =
    props;

  const [enterPressed, setEnterPressed] = useState<boolean>(false);

  const [isProjectTasksMenuOpen, setIsProjectTasksMenuOpen] = useState<boolean>(false);

  const tasksModalReference = useRef<HTMLButtonElement>(null);

  const handleProjectTasksMenuClose = () => {
    setIsProjectTasksMenuOpen(false);
  };

  const handleProjectsAutocompleteChange = event => {
    const { value } = event.target;
    if (event.code === KeyboardKeys.tab) return;
    if (event.code === KeyboardKeys.enter) {
      setEnterPressed(true);
      return;
    }
    if (currentValue.trackable?.name && value === currentValue.trackable.name) return;
    if (value !== name) {
      setName(value);
    }
  };

  const handleOptionClick = () => {
    setIsProjectTasksMenuOpen(true);
  };

  const handleCurrentTrackerTrackableChange = (value: ITimeTrackerTrackable) => {
    onCurrentValueChange({ task: null, trackable: value });
  };

  const handleTrackableWithTaskChange = (value: ITimeTrackerTrackableState) => {
    onCurrentValueChange(value);
  };

  useEffect(() => {
    if (enterPressed && options && options.length > 0) {
      if (options[0].tasks && options[0].tasks.length > 0) {
        handleCurrentTrackerTrackableChange(options[0]);
        setIsProjectTasksMenuOpen(true);
        setEnterPressed(false);
        return;
      }
      handleCurrentTrackerTrackableChange(options[0]);
      setEnterPressed(false);
      onClose();
    } else {
      setEnterPressed(false);
    }
  }, [options, enterPressed]);

  return (
    <Div sx={styles.select}>
      <ProjectTasksInput
        onInputChange={handleProjectsAutocompleteChange}
        placeholder={placeholder}
        inputValue={name}
        isFetching={isFetching}
        isError={isError}
        tasksModalReference={tasksModalReference}
      />
      <ProjectTasksOptionsList
        options={options}
        currentValue={currentValue}
        onClose={onClose}
        onOptionClick={handleOptionClick}
        onProjectTasksMenuClose={handleProjectTasksMenuClose}
        onTrackableWithTaskChange={handleTrackableWithTaskChange}
        isProjectTasksMenuOpen={isProjectTasksMenuOpen}
        tasksModalReference={tasksModalReference}
        onCurrentTrackerTrackableChange={handleCurrentTrackerTrackableChange}
      />
    </Div>
  );
};
export default TimeTrackerTrackablesAutocomplete;
