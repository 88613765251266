import { createContext, FC } from 'react';

import Modal from 'components/common/Modal';

import { IModalData } from 'hooks/types';
import { useModal } from 'hooks/useModal';

import { IModalProviderProps } from './types';

const ModalContext = createContext<{
  open: boolean;
  openModal: (_modalData: IModalData) => void;
  closeModal: () => void;
  modalData: IModalData | null;
}>({
  open: false,
  openModal: (_modalData: IModalData) => {},
  closeModal: () => {},
  modalData: null,
});

ModalContext.displayName = 'ModalContext';

const ModalProvider: FC<IModalProviderProps> = props => {
  const { children } = props;
  const { open, openModal, closeModal, modalData } = useModal();

  return (
    <ModalContext.Provider value={{ open, openModal, closeModal, modalData }}>
      {children}
      <Modal />
    </ModalContext.Provider>
  );
};

export default ModalProvider;
export { ModalContext };
